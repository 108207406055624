import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';
import ThingsToDoPage from './container/ThingsToDo';

const HomePage = React.lazy(() => import('./container/Home'));
const AboutPage = React.lazy(() => import('./container/About'));
const SpecialsPage = React.lazy(() => import('./container/Specials'));
const WeddingsPage = React.lazy(() => import('./container/Weddings'));
const RetreatsPage = React.lazy(() => import('./container/Retreats'));
const Retreats1Page = React.lazy(() => import('./container/Retreats1'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const ContactPage = React.lazy(() => import('./container/Contact'));
const PolicyPage = React.lazy(() => import('./container/Policies'));
const PressPage = React.lazy(() => import('./container/Press'));
const ItineraryPage = React.lazy(() => import('./container/Itineraries'));
const LiveStockPage = React.lazy(()=> import('./container/LiveStock'));
const MovieMakerPage = React.lazy(() => import('./container/MovieMaker'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <Switch>
          <Route path="/accommodations" component={() => <RoomsPage />} />
          <Route path="/specials" component={() => <SpecialsPage />} />
          <Route exact path="/thingstodo" component={CmsDataWrapper(ThingsToDoPage(), ['things-to-do'])} />
          <Route exact path="/dining" component={CmsDataWrapper(ThingsToDoPage('dining', false), ['dining'])} />
          <Route exact path="/weddings" component={() => <WeddingsPage />} />
          <Route exact path="/retreats" component={() => <RetreatsPage />} />
          <Route exact path="/retreats1" component={() => <Retreats1Page />} />
 
          <Route exact path="/the-ranch" component={() => <AboutPage />} />

          <Route exact path="/press" component={() => <PressPage />} />
          <Route exact path="/contact-us" component={() => <ContactPage />} />
          <Route exact path="/policies" component={() => <PolicyPage />} />
          <Route exact path="/itineraries/:slug" component={() => <ItineraryPage />} />
          <Route exact path="/livestock" component={() => <LiveStockPage />} />
          <Route exact path="/leisure-movie-maker" component={() => <MovieMakerPage />} />
          <Route exact path="/wedding-movie-maker" component={() => <MovieMakerPage type="wedding" />} />
          <Route exact path="/corporate-movie-maker" component={() => <MovieMakerPage type="corporate" />} />
          <Route path="/" component={() => <HomePage />} />
        </Switch>
        <Footer cmsData={coreData} />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
// export default withRouter(App);
